/* FORM START */

.contact-row {
  margin-left: 3.46vw;
  --bs-gutter-x: 0;
}

.contact-form input:focus::placeholder {
  color: transparent;
}

.contact-form textarea:focus::placeholder {
  color: transparent;
}

.contact-form {
  display: flex;
  flex-direction: column;
  width: min-content;
}

.contact-form h6 {
  font-family: var(--font-helvetica);
  font-weight: 500;
  font-size: 1vw;
  color: #7d7d7d;
  margin-bottom: 0.625vw;
  margin-top: 1.163vw;
}

.contact-form input {
  width: 17.031vw;
  height: 2.604vw;
  background: #292929;
  border: none;
  transition: 0.4s;
  -webkit-transition: 0.4s;
  font-family: var(--font-helvetica);
  font-weight: 300;
  font-size: 0.833vw;
  line-height: initial;
  color: #707070;
  padding: 0 1.042vw;
}

.contact-form input:hover {
  outline: none;
  -webkit-box-shadow: 0vw 0vw 0vw 0.12vw var(--color-red);
  -moz-box-shadow: 0vw 0vw 0vw 0.12vw var(--color-red);
  box-shadow: 0vw 0vw 0vw 0.12vw var(--color-red);
}

.contact-form input:focus {
  outline: none;
  -webkit-box-shadow: 0vw 0vw 0vw 0.208vw var(--color-red);
  -moz-box-shadow: 0vw 0vw 0vw 0.208vw var(--color-red);
  box-shadow: 0vw 0vw 0vw 0.208vw var(--color-red);
}

.contact-form textarea {
  width: 20.156vw;
  height: 10vw;
  background: #292929;
  font-family: var(--font-helvetica);
  font-weight: 300;
  font-size: 0.833vw;
  line-height: 1.25vw;
  color: #707070;
  transition: 0.4s;
  border: none;
  padding: 0.785vw 1.042vw 1.042vw 1.042vw;
}

.contact-form textarea:hover {
  outline: none;
  -webkit-box-shadow: 0vw 0vw 0vw 0.12vw var(--color-red);
  -moz-box-shadow: 0vw 0vw 0vw 0.12vw var(--color-red);
  box-shadow: 0vw 0vw 0vw 0.12vw var(--color-red);
}

.contact-form textarea:focus {
  outline: none;
  -webkit-box-shadow: 0vw 0vw 0vw 0.208vw var(--color-red);
  -moz-box-shadow: 0vw 0vw 0vw 0.208vw var(--color-red);
  box-shadow: 0vw 0vw 0vw 0.208vw var(--color-red);
}

.contact-form button {
  width: 8.073vw;
  height: 2.5vw;
  background: #992500;
  border: none;
  font-family: var(--font-helvetica);
  font-weight: 300;
  font-size: 0.938vw;
  text-align: center;
  color: #fff;
  line-height: initial;
  margin-top: 0.625vw;
}

/* FORM END */

/* CONTACT LINK START */
.contact-links-div {
  display: block;
}

.contact-links-div h6 {
  font-family: var(--font-helvetica);
  font-weight: 500;
  font-size: 1vw;
  color: #7d7d7d;
  margin-bottom: 0.7vw;
  margin-top: 1.163vw;
}

.contact-links {
  display: flex;
  flex-direction: column;
}

.contact-links a {
  width: min-content;
  color: #707070;
  font-size: 0.833vw;
  font-family: var(--font-helvetica);
  text-decoration: none;
  transition: 0.5s;
  margin-bottom: 0.5vw;
}

.contact-links a:hover {
  color: var(--color-red);
}
/* CONTACT LINK END */

/* CONTACT DESCRIPTION START */
.contact-description-div {
  display: block;
}

.contact-description-div h6 {
  font-family: var(--font-helvetica);
  font-weight: 500;
  font-size: 1vw;
  color: #7d7d7d;
  margin-bottom: 0.7vw;
  margin-top: 2.08vw;
}

.contact-description {
  padding-right: 0.7vw;
  font-family: var(--font-helvetica);
  font-weight: 500;
  font-size: 0.833vw;
  color: #7d7d7d;
  width: 20.583vw;
  max-width: 20.583vw;
  overflow-y: scroll;
  max-height: 12.458vw;
  line-height: 1.554vw;
}
/* CONTACT DESCRIPTION END */

/* CONTACT COVER START */
.contact-cover {
  width: 22.271vw;
  height: 40.188vw;
  overflow: hidden;
  position: absolute;
  top: 0;
  right: 0;
  z-index: -1;
}

.contact-cover img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
/* CONTACT COVER END */

/* \\\\RESPONSIVE//// */

@media (max-width: 1199px) {
  /* COVER */
  .contact-cover {
    width: 253px;
    height: 480px;
    overflow: hidden;
    position: absolute;
    top: 85px;
    right: 0;
  }

  /* FORM */
  .contact-row {
    margin-left: 0;
  }

  .contact-form {
    margin-left: 32px;
  }

  .contact-form h6 {
    font-family: var(--font-helvetica);
    font-weight: 500;
    font-size: 16px;
    color: #7d7d7d;
    margin-bottom: 14px;
    margin-top: 23px;
  }

  .contact-form input {
    width: 234px;
    height: 41px;
    transition: 0.4s;
    -webkit-transition: 0.4s;
    font-family: var(--font-helvetica);
    font-weight: 300;
    font-size: 14px;
    line-height: initial;
    color: #707070;
    padding: 0 16px;
  }

  .contact-form input:hover {
    outline: none;
    -webkit-box-shadow: 0vw 0px 0px 2.304px var(--color-red);
    -moz-box-shadow: 0px 0px 0px 2.304px var(--color-red);
    box-shadow: 0px 0px 0px 2.304px var(--color-red);
  }
  .contact-form input:focus {
    outline: none;
    -webkit-box-shadow: 0vw 0px 0px 3.994px var(--color-red);
    -moz-box-shadow: 0px 0px 0px 3.994px var(--color-red);
    box-shadow: 0px 0px 0px 3.994px var(--color-red);
  }

  .contact-form textarea {
    width: 287px;
    height: 158px;
    background: #292929;
    font-family: var(--font-helvetica);
    font-weight: 300;
    font-size: 14px;
    line-height: 24px;
    color: #707070;
    transition: 0.4s;
    border: none;
    padding: 10px 16px 16px 16px;
  }

  .contact-form textarea:hover {
    outline: none;
    -webkit-box-shadow: 0px 0px 0px 2.304px var(--color-red);
    -moz-box-shadow: 0px 0px 0px 2.304px var(--color-red);
    box-shadow: 0px 0px 0px 2.304px var(--color-red);
  }

  .contact-form textarea:focus {
    outline: none;
    -webkit-box-shadow: 0px 0px 0px 3.994px var(--color-red);
    -moz-box-shadow: 0px 0px 0px 3.994px var(--color-red);
    box-shadow: 0px 0px 0px 3.994px var(--color-red);
  }

  .contact-form button {
    width: 123px;
    height: 38px;
    background: #992500;
    border: none;
    font-family: var(--font-helvetica);
    font-weight: 300;
    font-size: 14px;
    margin-top: 12px;
  }

  /* LINKS */
  .contact-links-div h6 {
    font-size: 18px;
    margin-bottom: 23px;
    margin-top: 23px;
  }

  .contact-links a {
    margin-bottom: 18px;
    font-size: 14px;
  }

  /* DESCRIPTION */
  .contact-description-div h6 {
    font-size: 18px;
    margin-top: 52px;
    margin-bottom: 16px;
  }

  .contact-description {
    padding-right: 20px;
    font-size: 14px;
    max-width: 284px;
    max-height: 126px;
    line-height: 20px;
    width: 284px;
  }
}

@media (max-width: 991px) {
  /* COVER */
  .contact-cover {
    display: none;
  }
}

@media (max-width: 767px) {
  /* FORM */
  .contact-form {
    width: 100%;
    margin-left: 0;
    padding: 0 32px;
  }

  .contact-form input {
    width: 82%;
  }

  .contact-form textarea {
    width: 100%;
  }

  /* LINKS */
  .contact-links-div {
    width: 100%;
    padding: 0 32px;
  }

  .contact-links-div h6 {
    margin-top: 33px;
  }

  /* DESCRIPTION */
  .contact-description-div {
    width: 100%;
    padding: 0 32px;
  }

  .contact-description-div h6 {
    margin-top: 30px;
  }

  .contact-description {
    overflow-y: auto;
    width: 100%;
    max-width: 100%;
    max-height: 500px;
  }
}
