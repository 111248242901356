/* SIDEBAR */
.sidebar-div {
  position: relative;
  background-color: var(--color-black);
}

.sidebar-absolute-routes {
  display: none;
}
/* LOGO */
.sidebar-logo-div {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 11.786vw;
  background-color: var(--color-red);
}

.sidebar-logo-div a img {
  width: 18.594vw;
}

/* SOUND */

.sidebar-sound-div {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 3.953vw;
  background-color: var(--color-grey);
}

.sidebar-sound-div p {
  color: white;
  margin-bottom: 0;
  font-size: 0.878vw;
}

/* RESPONSIVE GUJBURGER */
.sidebar-gujburger-div {
  display: none;
}

.responsive-red-line {
  display: none;
}

/* NAVLINKS */

.sidebar-navlinks-div {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sidebar-navlinks-div::-webkit-scrollbar {
  display: none;
}

.sidebar-navlinks-div ul {
  margin-top: 2.05vw;
  list-style: none;
  margin-bottom: 0;
  padding-left: 0;
  text-align: start;
}

.sidebar-navlinks-div ul li a {
  font-size: 1.171vw;
  transition: 0.5s;
  line-height: 2.05vw;
  cursor: pointer;
  text-decoration: none;
  color: var(--color-light-grey);
}

.sidebar-navlinks-div ul li a:hover {
  color: var(--color-red) !important;
}

.sidebar-navlinks-div ul li a.active {
  color: var(--color-red) !important;
}

.nav-beflex {
  display: none;
}

@media (max-width: 1199px) {
  /* SIDEBAR */
  .sidebar-div {
    display: grid;
    grid-template-columns: 236px 1fr auto;
    position: relative;
    background-color: var(--color-black);
    height: 85px !important;
  }

  /* LOGO */
  .sidebar-logo-div {
    z-index: 11;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 236px;
    height: 85px;
    background-color: var(--color-red);
  }

  .sidebar-logo-div a img {
    width: 150px;
  }

  /* SOUND */

  .sidebar-sound-div {
    z-index: 11;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 85px;
    background-color: var(--color-grey);
  }

  .sidebar-sound-div p {
    color: white;
    margin-bottom: 0;
    font-size: 17px;
    margin-left: 66px;
  }

  /* RESPONSIVE GUJBURGER */
  .sidebar-gujburger-div {
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 85px;
    height: 85px;
    background-color: black;
    -webkit-background-color: black;
  }

  .menu-btn.open .menu-btn__burger::before {
    background: white;
  }

  .menu-btn.open .menu-btn__burger::after {
    background: white;
  }
  .sidebar-div .sidebar-bottom-div {
    display: none;
  }

  /* NAV LINKS */

  .sidebar-navlinks-div ul {
    height: 69vh;
    margin-top: 140px;
    text-align: center;
  }

  .sidebar-navlinks-div ul li a {
    font-size: 40px;
    transition: 0.5s;
    line-height: 60px;
    cursor: pointer;
    text-decoration: none;
    color: var(--color-light-grey);
  }

  .nav-beflex {
    margin-top: 100px;
    display: block;
  }

  .nav-beflex a {
    color: var(--color-red) !important;
  }
}

@media (max-width: 767px) {
  .sidebar-div {
    height: auto !important;
    grid-template-columns: 100%;
  }

  /* LOGO */
  .sidebar-logo-div {
    width: 100%;
    height: 135px;
  }

  .sidebar-logo-div a img {
    width: 241px;
  }

  /* SOUND */

  .sidebar-sound-div {
    justify-content: center;
    height: 57px;
  }

  .sidebar-sound-div p {
    color: white;
    margin-bottom: 0;
    font-size: 13px;
    margin-left: 0;
  }
}

@media (max-width: 767px) {
  .sidebar-absolute-routes {
    display: block;
    position: absolute;
    top: 206px;
    left: 60px;
    z-index: 20;
    font-family: var(--font-helvetica);
    font-weight: 500;
    font-size: 16px;
    text-align: left;
    color: var(--color-light-grey4);
  }

  /* RESPONSIVE GUJBURGER */
  .sidebar-gujburger-div {
    margin-left: 8px;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    background-color: transparent;
  }

  .responsive-red-line {
    display: block;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    height: 6px;
    background-color: var(--color-red);
  }

  .sidebar-div {
    background-color: white;
  }

  .menu-btn.open .menu-btn__burger::before {
    background: #606060;
  }

  .menu-btn.open .menu-btn__burger::after {
    background: #606060;
  }

  .sidebar-navlinks-div ul {
    height: 50vh;
    margin-top: 254px;
    text-align: start;
  }
}

@media (min-width: 1200px) {
  .transparent-close {
    display: none;
  }
}

@media (max-width: 1199px) and (min-width: 768px) {
  .lang-burger {
    z-index: 11;
  }

  .transparent-close {
    display: none;
    width: 85px;
    height: 85px;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 12;
  }

  .transparent-close.active {
    display: block;
  }
}

@media (max-width: 767px) {
  .transparent-close {
    width: 54px;
    height: 50px;
    position: absolute;
    left: 0;
    top: 140px;
    z-index: 13;
    display: none;
  }

  .transparent-close.active {
    top: 192px;
    display: block;
  }
}
