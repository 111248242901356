.lonely-line {
  width: 100vw;
  height: 0.878vw;
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: var(--color-light-grey2);
}

@media (max-width: 1199px) {
  .lonely-line {
    height: 9px;
    position: relative;
    background-color: #444444;
  }
}
