.video-slider .splide__slide {
  width: 20.677vw !important;
  height: 25.677vw;
  border: none !important;
  background: #202020;
}

.video-slider-div .splide__track ul {
  column-gap: 1.8vw;
}

.video-slider-div .splide__arrow--next {
  top: inherit !important;
  bottom: -5vw;
}

.video-slider-div .splide__arrow--prev {
  top: inherit !important;
  bottom: -5vw;
}

.video-slider .splide__slide div {
  width: 100%;
  height: 12.031vw;
  overflow: hidden;
}

.video-slider .splide__slide div:hover img {
  transform: scale(1.1);
}

.video-slider .splide__slide img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: 0.5s;
}

.slide__h3 {
  font-weight: 500;
  font-size: 1.25vw;
  line-height: 1.563vw;
  text-align: right;
  color: #992500;
  margin: 1.275vw 1.667vw;
}

.slide__p {
  height: 3.958vw;
  overflow-y: auto;
  font-weight: 300;
  font-size: 0.938vw;
  line-height: 1.354vw;
  text-align: right;
  color: #7d7d7d;
  margin: 0 0.833vw;
  padding: 0 0.521vw;
}

.v_slide__button {
  border: none;
  background: transparent;
  width: 4.323vw;
  margin-left: 75%;
  margin-top: 1.25vw;
}

.v_slide__button img {
  width: 100%;
}

@media (max-width: 1199px) {
  .video-slider-div ul {
    transform: translateX(0px) !important;
    display: grid !important;
    grid-template-columns: 1fr 1fr 1fr;
    row-gap: 28px;
    column-gap: 20px;
  }

  .video-slider .splide__slide {
    width: 295px !important;
    height: 424px !important;
  }

  .video-slider .splide__slide div {
    height: 192px;
  }

  .slide__h3 {
    font-size: 20px;
    margin: 30px 32px 20px 32px;
    line-height: 30px;
  }

  .slide__p {
    height: 64px;
    font-weight: 300;
    font-size: 14px;
    line-height: 22px;
    margin: 0 14px;
    padding: 0 12px;
  }

  .v_slide__button {
    margin-left: 63.05%;
    margin-top: 28px;
    width: 83px;
  }

  .video-slider-div .splide__arrows {
    display: none;
  }
}

@media (max-width: 991px) {
  .video-slider-div ul {
    grid-template-columns: 1fr 1fr;
    row-gap: 28px;
    column-gap: 20px;
  }
}

@media (max-width: 640px) {
  .video-slider-div ul {
    grid-template-columns: 1fr;
    row-gap: 28px;
    column-gap: 20px;
  }
}
