/* BOTTOM */

.mobile-footer-content {
 display: none;
}

.sidebar-bottom-div {
 position: absolute;
 margin-left: auto;
 margin-right: auto;
 right: 0;
 left: 0;
 bottom: 3vw;
 display: flex;
 align-items: center;
 flex-direction: column;
}

.mobile-footer-text {
 display: none;
}

.sidebar-bottom-div img {
 width: 7.687vw;
}

.sidebar-bottom-icons {
 display: flex;
 margin-top: 2.635vw;
 margin-bottom: 2.05vw;
}

.sidebar-bottom-icons svg {
 fill: var(--color-light-grey);
 max-width: 1.83vw;
 height: 1.318vw;
 margin: 0 0.366vw;
 transition: 0.5s;
}

.sidebar-bottom-icons svg:hover {
 fill: white;
}

.sidebar-bottom-copyright {
 color: var(--color-light-grey);
 font-size: 1.025vw;
}

@media (max-width: 1199px) {
 .sidebar-bottom-div {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  bottom: initial;
  display: flex;
  align-items: center;
  flex-direction: column;
 }

 .sidebar-bottom-div img {
  width: 106px;
 }

 .sidebar-bottom-icons {
  margin-top: 40px;
  margin-bottom: 26px;
 }

 .mobile-footer-text {
  display: block;
  color: var(--color-red);
  text-decoration: none;
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 18px;
 }

 .mobile-footer-text:hover {
  color: var(--color-red);
 }

 .sidebar-bottom-icons svg {
  margin: 0 9px;
  height: 16px;
  max-width: 21px;
 }

 .sidebar-bottom-copyright {
  font-size: 14px;
  margin-bottom: 54px;
 }

 .mobile-footer-content {
  display: flex;
  justify-content: space-between;
  width: 100%;
  position: relative;
  height: 40px;
 }

 .mobile-footer-content p {
  font-family: var(--font-palace) !important;
  font-size: 76px;
  color: #575757;
  position: absolute;
  bottom: 10px;
  left: 10px;
  line-height: 26px;
 }

 .mobile-footer-content img {
  position: absolute;
  right: 0;
  bottom: -7.5px;
  width: 146px;
 }

 .mobile-footer-mails {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 10px;
  margin-bottom: 36px;
 }

 .mobile-footer-mails a {
  font-weight: 300;
  font-size: 18px;
  color: #707070;
  text-decoration: none;
 }

 .mobile-footer {
  padding-top: 52px;
 }
}

@media (max-width: 355px) {
 .mobile-footer-content img {
  width: 100px;
 }
}
