/* \===/ GUJSTRAP \===/ */

@media (min-width: 1200px) {
  .vh-xl-100 {
    height: 100vh;
  }
}

.gutter-0 {
  --bs-gutter-x: 0;
}

.b-red {
  border: 1px solid red;
}

.b2-red {
  border: 2px solid red;
}

.b3-red {
  border: 3px solid red;
}

p {
  margin-bottom: 0;
}

body {
  background-color: var(--color-background);
  overflow-x: hidden;
}

.gj-box {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (min-width: 1200px) {
  body {
    overflow-x: hidden;
  }

  .sidebar-div {
    width: 27vw;
  }

  .main-col {
    width: 73vw;
  }

  .pup {
    display: grid !important;
    grid-template-columns: 27vw 1fr;
  }

  .sidebar-div {
    width: 27vw;
  }

  .main-col {
    width: 73vw;
  }
}

@media (max-width: 767px) {
  .max-md-none {
    display: none;
  }
}
