footer {
 display: flex;
}

.mobile-footer {
 display: none;
}

/* FOOTER AND ICON */

.footer-paata-beridze {
 font-size: 10.833vw;
 position: absolute;
 bottom: 1.464vw;
 color: var(--color-light-grey);
 font-family: var(--font-palace) !important;
 right: 32.5vw;
 line-height: 9vw;
 user-select: none;
}

.footer-img {
 position: absolute;
 bottom: 0.2vw;
 right: 20vw;
 height: 14vw;
 z-index: -1;
}

/* FOOTER LINKS */

.footer-links-div {
 display: flex;
 flex-direction: column;
 position: absolute;
 bottom: 3.3vw;
 right: 2.6vw;
}

.footer-link {
 font-size: 1.171vw;
 color: var(--color-light-grey);
 text-align: end;
 transition: 0.5s;
 text-decoration: none;
 margin-bottom: 0.438vw;
}

.footer-creator {
 margin-top: 1vw;
}

.footer-link:hover {
 color: var(--color-red);
}

.footer-creator {
 color: var(--color-red);
}

@media (max-width: 1199px) {
 footer {
  display: none;
 }

 .mobile-footer {
  display: block;
 }
}
