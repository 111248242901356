@import url(http://fonts.cdnfonts.com/css/helvetica-neue-9);
/* BOTTOM */

.mobile-footer-content {
 display: none;
}

.sidebar-bottom-div {
 position: absolute;
 margin-left: auto;
 margin-right: auto;
 right: 0;
 left: 0;
 bottom: 3vw;
 display: flex;
 align-items: center;
 flex-direction: column;
}

.mobile-footer-text {
 display: none;
}

.sidebar-bottom-div img {
 width: 7.687vw;
}

.sidebar-bottom-icons {
 display: flex;
 margin-top: 2.635vw;
 margin-bottom: 2.05vw;
}

.sidebar-bottom-icons svg {
 fill: var(--color-light-grey);
 max-width: 1.83vw;
 height: 1.318vw;
 margin: 0 0.366vw;
 transition: 0.5s;
}

.sidebar-bottom-icons svg:hover {
 fill: white;
}

.sidebar-bottom-copyright {
 color: var(--color-light-grey);
 font-size: 1.025vw;
}

@media (max-width: 1199px) {
 .sidebar-bottom-div {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  bottom: auto;
  bottom: initial;
  display: flex;
  align-items: center;
  flex-direction: column;
 }

 .sidebar-bottom-div img {
  width: 106px;
 }

 .sidebar-bottom-icons {
  margin-top: 40px;
  margin-bottom: 26px;
 }

 .mobile-footer-text {
  display: block;
  color: var(--color-red);
  text-decoration: none;
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 18px;
 }

 .mobile-footer-text:hover {
  color: var(--color-red);
 }

 .sidebar-bottom-icons svg {
  margin: 0 9px;
  height: 16px;
  max-width: 21px;
 }

 .sidebar-bottom-copyright {
  font-size: 14px;
  margin-bottom: 54px;
 }

 .mobile-footer-content {
  display: flex;
  justify-content: space-between;
  width: 100%;
  position: relative;
  height: 40px;
 }

 .mobile-footer-content p {
  font-family: var(--font-palace) !important;
  font-size: 76px;
  color: #575757;
  position: absolute;
  bottom: 10px;
  left: 10px;
  line-height: 26px;
 }

 .mobile-footer-content img {
  position: absolute;
  right: 0;
  bottom: -7.5px;
  width: 146px;
 }

 .mobile-footer-mails {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-row-gap: 10px;
  row-gap: 10px;
  margin-bottom: 36px;
 }

 .mobile-footer-mails a {
  font-weight: 300;
  font-size: 18px;
  color: #707070;
  text-decoration: none;
 }

 .mobile-footer {
  padding-top: 52px;
 }
}

@media (max-width: 355px) {
 .mobile-footer-content img {
  width: 100px;
 }
}

.main-lang-div {
  position: absolute;
  width: 9.736vw;
  height: 3.807vw;
  overflow: hidden;
  top: 0;
  right: 0;
  transition: height 0.5s ease-in-out;
  z-index: 110000000;
}

.main-lang-div:hover {
  height: 11.42vw;
  transition: all 0.3s ease-in-out;
}

.active-lang {
  margin-left: 1.245vw;
  margin-right: 1.391vw;
}

.lang-div {
  width: 9.736vw;
  height: 3.807vw;
  background-color: #202020;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  cursor: pointer;
  text-transform: capitalize;
  color: #bebebe;
  font-family: var(--font-helvetica);
}

.lang-icon-div {
  width: 1.318vw;
}

.lang-icon {
  width: 100%;
  height: 100%;
}

.main-lang-div:hover .active-lang-div .lang-arrow-div {
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
}

.lang-arrow-div {
  width: 1.318vw;
  transition: all 0.3s ease-in-out;
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

.lang-arrow {
  width: 100%;
  height: 100%;
}

.lang-div:hover {
  background-color: #992500;
}

.lang-div p {
  font-size: 1vw;
}

@media (max-width: 1199px) {
  .main-lang-div {
    z-index: 11;
    position: absolute;
    width: 133px;
    height: 85px;
    overflow: hidden;
    top: 0;
    right: 85px;
    transition: height 0.5s ease-in-out;
  }

  .main-lang-div:hover {
    height: 189px;
    transition: all 0.3s ease-in-out;
  }

  .active-lang {
    margin-left: 18px;
    margin-right: 18px;
  }

  .lang-div {
    width: 133px;
    height: 52px;
    background-color: #202020;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    cursor: pointer;
    text-transform: capitalize;
    color: #bebebe;
    font-family: var(--font-helvetica);
  }

  .active-lang-div {
    width: 133px;
    height: 85px;
  }

  .lang-icon-div {
    width: 15px;
  }

  .lang-icon {
    width: 100%;
    height: 100%;
  }

  .main-lang-div:hover .active-lang-div .lang-arrow-div {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }

  .lang-arrow-div {
    width: 15px;
    transition: all 0.3s ease-in-out;
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }

  .lang-arrow {
    width: 100%;
    height: 100%;
  }

  .lang-div:hover {
    background-color: #992500;
  }

  .lang-div p {
    font-size: 16px;
  }
}

@media (max-width: 767px) {
  .main-lang-div {
    top: 0;
    right: 23px;
    position: absolute;
    display: flex;
    width: auto;
    height: auto;
    margin-top: 10px;
  }

  .active-lang-div {
    height: -webkit-min-content;
    height: min-content;
    width: 34px;
  }

  .lang-div {
    background-color: transparent;
    height: -webkit-min-content;
    height: min-content;
    width: 40px;
  }

  .lang-arrow-div {
    display: none;
  }

  .lang-icon-div {
    display: none;
  }

  .lang-burger {
    z-index: 11;
    background-color: white;
    height: 54px;
    position: relative;
  }

  .lang-div:hover {
    background-color: transparent;
    color: var(--color-red);
  }

  .lang-div p {
    color: black;
  }

  .active-lang-div p {
    color: var(--color-red);
  }

  @supports (position: sticky) {
    .lang-burger {
      position: sticky;
    }
  }
}

@media (min-width: 1200px) {
  .lang-burger {
    width: 100vw;
    position: fixed;
    top: 0;
  }
}

.blaaaa {
  width: 40px;
  height: 40px;
  border: 1px solid red;
  position: absolute;
  top: 4px;
  left: 200px;
  background-color: transparent;
}

/* SIDEBAR */
.sidebar-div {
  position: relative;
  background-color: var(--color-black);
}

.sidebar-absolute-routes {
  display: none;
}
/* LOGO */
.sidebar-logo-div {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 11.786vw;
  background-color: var(--color-red);
}

.sidebar-logo-div a img {
  width: 18.594vw;
}

/* SOUND */

.sidebar-sound-div {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 3.953vw;
  background-color: var(--color-grey);
}

.sidebar-sound-div p {
  color: white;
  margin-bottom: 0;
  font-size: 0.878vw;
}

/* RESPONSIVE GUJBURGER */
.sidebar-gujburger-div {
  display: none;
}

.responsive-red-line {
  display: none;
}

/* NAVLINKS */

.sidebar-navlinks-div {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sidebar-navlinks-div::-webkit-scrollbar {
  display: none;
}

.sidebar-navlinks-div ul {
  margin-top: 2.05vw;
  list-style: none;
  margin-bottom: 0;
  padding-left: 0;
  text-align: start;
}

.sidebar-navlinks-div ul li a {
  font-size: 1.171vw;
  transition: 0.5s;
  line-height: 2.05vw;
  cursor: pointer;
  text-decoration: none;
  color: var(--color-light-grey);
}

.sidebar-navlinks-div ul li a:hover {
  color: var(--color-red) !important;
}

.sidebar-navlinks-div ul li a.active {
  color: var(--color-red) !important;
}

.nav-beflex {
  display: none;
}

@media (max-width: 1199px) {
  /* SIDEBAR */
  .sidebar-div {
    display: grid;
    grid-template-columns: 236px 1fr auto;
    position: relative;
    background-color: var(--color-black);
    height: 85px !important;
  }

  /* LOGO */
  .sidebar-logo-div {
    z-index: 11;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 236px;
    height: 85px;
    background-color: var(--color-red);
  }

  .sidebar-logo-div a img {
    width: 150px;
  }

  /* SOUND */

  .sidebar-sound-div {
    z-index: 11;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 85px;
    background-color: var(--color-grey);
  }

  .sidebar-sound-div p {
    color: white;
    margin-bottom: 0;
    font-size: 17px;
    margin-left: 66px;
  }

  /* RESPONSIVE GUJBURGER */
  .sidebar-gujburger-div {
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 85px;
    height: 85px;
    background-color: black;
    -webkit-background-color: black;
  }

  .menu-btn.open .menu-btn__burger::before {
    background: white;
  }

  .menu-btn.open .menu-btn__burger::after {
    background: white;
  }
  .sidebar-div .sidebar-bottom-div {
    display: none;
  }

  /* NAV LINKS */

  .sidebar-navlinks-div ul {
    height: 69vh;
    margin-top: 140px;
    text-align: center;
  }

  .sidebar-navlinks-div ul li a {
    font-size: 40px;
    transition: 0.5s;
    line-height: 60px;
    cursor: pointer;
    text-decoration: none;
    color: var(--color-light-grey);
  }

  .nav-beflex {
    margin-top: 100px;
    display: block;
  }

  .nav-beflex a {
    color: var(--color-red) !important;
  }
}

@media (max-width: 767px) {
  .sidebar-div {
    height: auto !important;
    grid-template-columns: 100%;
  }

  /* LOGO */
  .sidebar-logo-div {
    width: 100%;
    height: 135px;
  }

  .sidebar-logo-div a img {
    width: 241px;
  }

  /* SOUND */

  .sidebar-sound-div {
    justify-content: center;
    height: 57px;
  }

  .sidebar-sound-div p {
    color: white;
    margin-bottom: 0;
    font-size: 13px;
    margin-left: 0;
  }
}

@media (max-width: 767px) {
  .sidebar-absolute-routes {
    display: block;
    position: absolute;
    top: 206px;
    left: 60px;
    z-index: 20;
    font-family: var(--font-helvetica);
    font-weight: 500;
    font-size: 16px;
    text-align: left;
    color: var(--color-light-grey4);
  }

  /* RESPONSIVE GUJBURGER */
  .sidebar-gujburger-div {
    margin-left: 8px;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    background-color: transparent;
  }

  .responsive-red-line {
    display: block;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    height: 6px;
    background-color: var(--color-red);
  }

  .sidebar-div {
    background-color: white;
  }

  .menu-btn.open .menu-btn__burger::before {
    background: #606060;
  }

  .menu-btn.open .menu-btn__burger::after {
    background: #606060;
  }

  .sidebar-navlinks-div ul {
    height: 50vh;
    margin-top: 254px;
    text-align: start;
  }
}

@media (min-width: 1200px) {
  .transparent-close {
    display: none;
  }
}

@media (max-width: 1199px) and (min-width: 768px) {
  .lang-burger {
    z-index: 11;
  }

  .transparent-close {
    display: none;
    width: 85px;
    height: 85px;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 12;
  }

  .transparent-close.active {
    display: block;
  }
}

@media (max-width: 767px) {
  .transparent-close {
    width: 54px;
    height: 50px;
    position: absolute;
    left: 0;
    top: 140px;
    z-index: 13;
    display: none;
  }

  .transparent-close.active {
    top: 192px;
    display: block;
  }
}

body.offcanvas-active {
  overflow: hidden;
}

.offcanvas-header {
  display: none;
}

.screen-darken {
  height: 100%;
  width: 0%;
  z-index: 30;
  position: fixed;
  top: 0;
  right: 0;
  opacity: 0;
  visibility: hidden;
  background-color: rgba(13, 13, 13, 0.94);
  transition: opacity 0.2s;
}

.screen-darken.active {
  z-index: 10;
  transition: opacity 0.3s, width 0s;
  opacity: 1;
  width: 100%;
  visibility: visible;
}

/* ============ mobile view ============ */
@media all and (max-width: 1199px) {
  .offcanvas-header {
    display: block;
  }

  .mobile-offcanvas {
    visibility: hidden;
    -webkit-transform: translatey(-100%);
            transform: translatey(-100%);
    border-radius: 0;
    display: block;
    position: absolute;
    margin: 0 auto;
    top: 0;
    left: 0;
    right: 0;
    height: auto;
    z-index: 10;
    width: 300px;
    overflow-y: scroll;
    overflow-x: hidden;
    transition: visibility 0.3s;
  }

  .mobile-offcanvas.show {
    visibility: visible;
    -webkit-transform: translatey(0);
            transform: translatey(0);
  }
  .mobile-offcanvas .container,
  .mobile-offcanvas .container-fluid {
    display: block;
  }
}
/* ============ mobile view .end// ============ */

.menu-btn {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  cursor: pointer;
  transition: all 0.5s ease-in-out;
  /* border: 3px solid #fff; */
  background-color: black;
}
.menu-btn__burger {
  width: 27px;
  height: 3px;
  background: #c8c8c8;
  transition: all 0.5s ease-in-out;
}
.menu-btn__burger::before,
.menu-btn__burger::after {
  content: "";
  position: absolute;
  width: 27px;
  height: 3px;
  background: #c8c8c8;
  transition: all 0.5s ease-in-out;
}
.menu-btn__burger::before {
  -webkit-transform: translateY(-10px);
          transform: translateY(-10px);
}
.menu-btn__burger::after {
  -webkit-transform: translateY(10px);
          transform: translateY(10px);
}
/* ANIMATION */
.menu-btn.open .menu-btn__burger {
  background: transparent;
  box-shadow: none;
}
.menu-btn.open .menu-btn__burger::before {
  -webkit-transform: rotate(45deg) translate(-1px, 1px);
          transform: rotate(45deg) translate(-1px, 1px);
}
.menu-btn.open .menu-btn__burger::after {
  -webkit-transform: rotate(-45deg) translate(-1px, -1px);
          transform: rotate(-45deg) translate(-1px, -1px);
}

@media (max-width: 767px) {
  .menu-btn__burger {
    width: 27px;
    height: 3px;
    background: #606060;
    transition: all 0.5s ease-in-out;
  }
  .menu-btn__burger::before,
  .menu-btn__burger::after {
    content: "";
    position: absolute;
    width: 27px;
    height: 3px;
    background: #606060;
    transition: all 0.5s ease-in-out;
  }

  .menu-btn {
    background-color: white;
    width: 50px;
    height: 50px;
  }

  .menu-btn__burger::before {
    -webkit-transform: translateY(-8px);
            transform: translateY(-8px);
  }

  .menu-btn__burger::after {
    -webkit-transform: translateY(8px);
            transform: translateY(8px);
  }

  .sidebar-navlinks-div ul li a {
    font-size: 24px;
    line-height: 38px;
  }

  .nav-beflex {
    display: none;
  }
}

@media (max-width: 767px) {
  .mobile-offcanvas {
    -webkit-transform: translatex(-100%);
            transform: translatex(-100%);
    width: auto;
    padding: 0px 22px;
  }

  .mobile-offcanvas.show {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}

footer {
 display: flex;
}

.mobile-footer {
 display: none;
}

/* FOOTER AND ICON */

.footer-paata-beridze {
 font-size: 10.833vw;
 position: absolute;
 bottom: 1.464vw;
 color: var(--color-light-grey);
 font-family: var(--font-palace) !important;
 right: 32.5vw;
 line-height: 9vw;
 -webkit-user-select: none;
     -ms-user-select: none;
         user-select: none;
}

.footer-img {
 position: absolute;
 bottom: 0.2vw;
 right: 20vw;
 height: 14vw;
 z-index: -1;
}

/* FOOTER LINKS */

.footer-links-div {
 display: flex;
 flex-direction: column;
 position: absolute;
 bottom: 3.3vw;
 right: 2.6vw;
}

.footer-link {
 font-size: 1.171vw;
 color: var(--color-light-grey);
 text-align: end;
 transition: 0.5s;
 text-decoration: none;
 margin-bottom: 0.438vw;
}

.footer-creator {
 margin-top: 1vw;
}

.footer-link:hover {
 color: var(--color-red);
}

.footer-creator {
 color: var(--color-red);
}

@media (max-width: 1199px) {
 footer {
  display: none;
 }

 .mobile-footer {
  display: block;
 }
}

.lonely-line {
  width: 100vw;
  height: 0.878vw;
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: var(--color-light-grey2);
}

@media (max-width: 1199px) {
  .lonely-line {
    height: 9px;
    position: relative;
    background-color: #444444;
  }
}

@font-face {
 font-family: "palace";
 src: url(/static/media/palace.0d3df942.ttf) format("truetype");
}

.home-slider-div {
  position: relative;
}

.primary-slider {
  width: 100%;
  max-width: 100%;
}

.secondary-slider .splide__track ul {
  grid-column-gap: 1.1vw;
  -webkit-column-gap: 1.1vw;
          column-gap: 1.1vw;
}

.primary-slider img {
  max-width: 100%;
  object-fit: cover;
  width: 100%;
  height: 100%;
  max-height: 36.4vw;
}

.primary-slider button {
  display: none;
}

.secondary-slider {
  right: 2.76vw;
  bottom: -3.802vw;
  margin: 0 auto;
  position: absolute !important;
  max-width: 47vw !important;
}

.secondary-slider .splide__slide {
  transition: 0.3s;
  height: 8.802vw;
  border: 0.677vw solid #3f3f3f !important;
}

.secondary-slider .splide__slide:hover {
  border-color: var(--color-red) !important;
}

.secondary-slider .splide__slide.is-active {
  border: 0.677vw solid var(--color-red) !important;
}

.secondary-slider .splide__slide img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media (max-width: 1199px) {
  .secondary-slider .splide__slide:hover {
    border-color: #3f3f3f !important;
  }

  .home-slider-div {
    margin-bottom: 100px;
  }

  .primary-slider img {
    max-height: 400px;
  }

  .secondary-slider .splide__slide {
    height: 105px;
    border: 6px solid #3f3f3f !important;
  }

  .secondary-slider .splide__slide.is-active {
    border: 6px solid var(--color-red) !important;
  }

  .secondary-slider .splide__track ul {
    grid-column-gap: 16px;
    -webkit-column-gap: 16px;
            column-gap: 16px;
  }

  .secondary-slider {
    max-width: 572px !important;
  }
}

@media (max-width: 767px) {
  .primary-slider img {
    max-height: 300px;
  }
}

@media (max-width: 620px) {
  .secondary-slider .splide__slide {
    height: 16vw;
    border: 1.6vw solid #992500;
  }

  .secondary-slider .splide__track ul {
    max-width: 90.95vw;
    grid-gap: 4.267vw;
  }

  .secondary-slider {
    left: 50%; /* Position X halfway in */
    -webkit-transform: translate(-50%, 0%);
            transform: translate(-50%, 0%);
    right: auto;
  }
}

@media (max-width: 575px) {
  .primary-slider img {
    max-height: 200px;
  }
}

/* FORM START */

.contact-row {
  margin-left: 3.46vw;
  --bs-gutter-x: 0;
}

.contact-form input:focus::-webkit-input-placeholder {
  color: transparent;
}

.contact-form input:focus:-ms-input-placeholder {
  color: transparent;
}

.contact-form input:focus::placeholder {
  color: transparent;
}

.contact-form textarea:focus::-webkit-input-placeholder {
  color: transparent;
}

.contact-form textarea:focus:-ms-input-placeholder {
  color: transparent;
}

.contact-form textarea:focus::placeholder {
  color: transparent;
}

.contact-form {
  display: flex;
  flex-direction: column;
  width: -webkit-min-content;
  width: min-content;
}

.contact-form h6 {
  font-family: var(--font-helvetica);
  font-weight: 500;
  font-size: 1vw;
  color: #7d7d7d;
  margin-bottom: 0.625vw;
  margin-top: 1.163vw;
}

.contact-form input {
  width: 17.031vw;
  height: 2.604vw;
  background: #292929;
  border: none;
  transition: 0.4s;
  -webkit-transition: 0.4s;
  font-family: var(--font-helvetica);
  font-weight: 300;
  font-size: 0.833vw;
  line-height: normal;
  line-height: initial;
  color: #707070;
  padding: 0 1.042vw;
}

.contact-form input:hover {
  outline: none;
  box-shadow: 0vw 0vw 0vw 0.12vw var(--color-red);
}

.contact-form input:focus {
  outline: none;
  box-shadow: 0vw 0vw 0vw 0.208vw var(--color-red);
}

.contact-form textarea {
  width: 20.156vw;
  height: 10vw;
  background: #292929;
  font-family: var(--font-helvetica);
  font-weight: 300;
  font-size: 0.833vw;
  line-height: 1.25vw;
  color: #707070;
  transition: 0.4s;
  border: none;
  padding: 0.785vw 1.042vw 1.042vw 1.042vw;
}

.contact-form textarea:hover {
  outline: none;
  box-shadow: 0vw 0vw 0vw 0.12vw var(--color-red);
}

.contact-form textarea:focus {
  outline: none;
  box-shadow: 0vw 0vw 0vw 0.208vw var(--color-red);
}

.contact-form button {
  width: 8.073vw;
  height: 2.5vw;
  background: #992500;
  border: none;
  font-family: var(--font-helvetica);
  font-weight: 300;
  font-size: 0.938vw;
  text-align: center;
  color: #fff;
  line-height: normal;
  line-height: initial;
  margin-top: 0.625vw;
}

/* FORM END */

/* CONTACT LINK START */
.contact-links-div {
  display: block;
}

.contact-links-div h6 {
  font-family: var(--font-helvetica);
  font-weight: 500;
  font-size: 1vw;
  color: #7d7d7d;
  margin-bottom: 0.7vw;
  margin-top: 1.163vw;
}

.contact-links {
  display: flex;
  flex-direction: column;
}

.contact-links a {
  width: -webkit-min-content;
  width: min-content;
  color: #707070;
  font-size: 0.833vw;
  font-family: var(--font-helvetica);
  text-decoration: none;
  transition: 0.5s;
  margin-bottom: 0.5vw;
}

.contact-links a:hover {
  color: var(--color-red);
}
/* CONTACT LINK END */

/* CONTACT DESCRIPTION START */
.contact-description-div {
  display: block;
}

.contact-description-div h6 {
  font-family: var(--font-helvetica);
  font-weight: 500;
  font-size: 1vw;
  color: #7d7d7d;
  margin-bottom: 0.7vw;
  margin-top: 2.08vw;
}

.contact-description {
  padding-right: 0.7vw;
  font-family: var(--font-helvetica);
  font-weight: 500;
  font-size: 0.833vw;
  color: #7d7d7d;
  width: 20.583vw;
  max-width: 20.583vw;
  overflow-y: scroll;
  max-height: 12.458vw;
  line-height: 1.554vw;
}
/* CONTACT DESCRIPTION END */

/* CONTACT COVER START */
.contact-cover {
  width: 22.271vw;
  height: 40.188vw;
  overflow: hidden;
  position: absolute;
  top: 0;
  right: 0;
  z-index: -1;
}

.contact-cover img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
/* CONTACT COVER END */

/* \\\\RESPONSIVE//// */

@media (max-width: 1199px) {
  /* COVER */
  .contact-cover {
    width: 253px;
    height: 480px;
    overflow: hidden;
    position: absolute;
    top: 85px;
    right: 0;
  }

  /* FORM */
  .contact-row {
    margin-left: 0;
  }

  .contact-form {
    margin-left: 32px;
  }

  .contact-form h6 {
    font-family: var(--font-helvetica);
    font-weight: 500;
    font-size: 16px;
    color: #7d7d7d;
    margin-bottom: 14px;
    margin-top: 23px;
  }

  .contact-form input {
    width: 234px;
    height: 41px;
    transition: 0.4s;
    -webkit-transition: 0.4s;
    font-family: var(--font-helvetica);
    font-weight: 300;
    font-size: 14px;
    line-height: normal;
    line-height: initial;
    color: #707070;
    padding: 0 16px;
  }

  .contact-form input:hover {
    outline: none;
    box-shadow: 0px 0px 0px 2.304px var(--color-red);
  }
  .contact-form input:focus {
    outline: none;
    box-shadow: 0px 0px 0px 3.994px var(--color-red);
  }

  .contact-form textarea {
    width: 287px;
    height: 158px;
    background: #292929;
    font-family: var(--font-helvetica);
    font-weight: 300;
    font-size: 14px;
    line-height: 24px;
    color: #707070;
    transition: 0.4s;
    border: none;
    padding: 10px 16px 16px 16px;
  }

  .contact-form textarea:hover {
    outline: none;
    box-shadow: 0px 0px 0px 2.304px var(--color-red);
  }

  .contact-form textarea:focus {
    outline: none;
    box-shadow: 0px 0px 0px 3.994px var(--color-red);
  }

  .contact-form button {
    width: 123px;
    height: 38px;
    background: #992500;
    border: none;
    font-family: var(--font-helvetica);
    font-weight: 300;
    font-size: 14px;
    margin-top: 12px;
  }

  /* LINKS */
  .contact-links-div h6 {
    font-size: 18px;
    margin-bottom: 23px;
    margin-top: 23px;
  }

  .contact-links a {
    margin-bottom: 18px;
    font-size: 14px;
  }

  /* DESCRIPTION */
  .contact-description-div h6 {
    font-size: 18px;
    margin-top: 52px;
    margin-bottom: 16px;
  }

  .contact-description {
    padding-right: 20px;
    font-size: 14px;
    max-width: 284px;
    max-height: 126px;
    line-height: 20px;
    width: 284px;
  }
}

@media (max-width: 991px) {
  /* COVER */
  .contact-cover {
    display: none;
  }
}

@media (max-width: 767px) {
  /* FORM */
  .contact-form {
    width: 100%;
    margin-left: 0;
    padding: 0 32px;
  }

  .contact-form input {
    width: 82%;
  }

  .contact-form textarea {
    width: 100%;
  }

  /* LINKS */
  .contact-links-div {
    width: 100%;
    padding: 0 32px;
  }

  .contact-links-div h6 {
    margin-top: 33px;
  }

  /* DESCRIPTION */
  .contact-description-div {
    width: 100%;
    padding: 0 32px;
  }

  .contact-description-div h6 {
    margin-top: 30px;
  }

  .contact-description {
    overflow-y: auto;
    width: 100%;
    max-width: 100%;
    max-height: 500px;
  }
}

/* \===/ GUJSTRAP \===/ */

@media (min-width: 1200px) {
  .vh-xl-100 {
    height: 100vh;
  }
}

.gutter-0 {
  --bs-gutter-x: 0;
}

.b-red {
  border: 1px solid red;
}

.b2-red {
  border: 2px solid red;
}

.b3-red {
  border: 3px solid red;
}

p {
  margin-bottom: 0;
}

body {
  background-color: var(--color-background);
  overflow-x: hidden;
}

.gj-box {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (min-width: 1200px) {
  body {
    overflow-x: hidden;
  }

  .sidebar-div {
    width: 27vw;
  }

  .main-col {
    width: 73vw;
  }

  .pup {
    display: grid !important;
    grid-template-columns: 27vw 1fr;
  }

  .sidebar-div {
    width: 27vw;
  }

  .main-col {
    width: 73vw;
  }
}

@media (max-width: 767px) {
  .max-md-none {
    display: none;
  }
}

/* =\\=== R O O T ===//= */

:root {
 /* colors: */
 --color-red: #992500;
 --color-grey: #2e2e2e;
 --color-black: #0d0d0d;
 --color-light-grey: #707070;
 --color-light-grey2: #4c4c4c;
 --color-light-grey3: #c8c8c8;
 --color-light-grey4: #606060;
 --color-background: #191919;

 /* fonts: */
 --font-palace: "palace", sans-serif;
 --font-helvetica: "Helvetica Neue", sans-serif;
}

* {
 font-family: "Helvetica Neue", sans-serif !important;
 font-family: var(--font-helvetica) !important;
}

.footer-paata-beridze {
 font-family: "palace", sans-serif !important;
 font-family: var(--font-palace) !important;
}

*::selection {
 background-color: #992500;
 background-color: var(--color-red);
}

/* =\\=== S C R O L L ===//= */

::-webkit-scrollbar,
::-webkit-scrollbar-thumb,
::-webkit-scrollbar-track {
 width: 6px;
 border: none;
 background: transparent;
}

::-webkit-scrollbar-button,
::-webkit-scrollbar-track-piece,
::-webkit-scrollbar-corner,
::-webkit-resizer {
 display: none;
}

::-webkit-scrollbar-thumb {
 border-radius: 6px;
 background-color: #4a4a4a;
}

::-webkit-scrollbar-track {
 background-image: url(/static/media/vertical-scroll.11662ba6.svg);
 background-repeat: repeat-y;
 background-size: contain;
}

.bio-swap {
  --bs-gutter-x: 0;
  flex-direction: row-reverse;
}

.biography-photo-div {
  margin-left: auto;
  width: 24.271vw;
  height: 38.188vw;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.biography-photo-div img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.page-title {
  text-transform: capitalize;
  font-family: var(--font-helvetica);
  font-weight: 300;
  font-size: 3.125vw;
  color: var(--color-light-grey);
  margin-left: 3.75vw;
  margin-top: 3.333vw;
}

.biography-text-div {
  margin-top: 2.708vw;
  margin-left: 3.75vw;
  padding-right: 4vw;
  width: 40.292vw;
  height: 22.958vw;
  overflow-y: auto;
  font-family: var(--font-helvetica);
  font-weight: 300;
  font-size: 0.938vw;
  line-height: 1.354vw;
  color: var(--color-light-grey);
}

@media (max-width: 1200px) {
  .biography-photo-div {
    width: 100%;
    height: 400px;
  }

  .biography-photo-div img {
    object-fit: cover;
  }

  .page-title {
    width: auto;
    font-size: 40px;
    margin-left: 32px;
    margin-right: 32px;
    margin-top: 24px;
  }

  .biography-text-div {
    line-height: 32px;
    font-size: 20px;
    margin-top: 18px;
    width: auto;
    margin-left: 32px;
    margin-right: 32px;
    padding-right: 32px;
    height: 520px;
  }
}

@media (max-width: 767px) {
  .biography-photo-div {
    width: 100%;
    height: 200px;
  }

  .page-title {
    width: auto;
    font-size: 35px;
    margin-left: 32px;
    margin-right: 32px;
    margin-top: 24px;
  }

  .biography-text-div {
    line-height: 26px;
    font-size: 15px;
    margin-top: 18px;
    width: auto;
    margin-left: 32px;
    margin-right: 32px;
    padding-right: 32px;
    height: 320px;
  }
}

.partners-div {
    width: auto;
    height: 32.292vw;
    margin: 0 3.75vw;
    overflow-y: auto;
    margin-top: 2.865vw;
}

.partners-div a img {
    width: 5.729vw;
    height: 4.063vw;
    object-fit: cover;
    margin-right: 0.781vw;
    margin-bottom: 1.146vw;
}

@media (max-width: 1199px) {
    .partners-div {
        padding-right: 20px;
        width: auto;
        height: 500px;
        margin: 0 32px;
        overflow-y: auto;
        margin-top: 2.865vw;
    }
    
    .partners-div a img {
        width: 110px;
        height: 80px;
        object-fit: cover;
        margin-right: 15px;
        margin-bottom: 22px;
    }
}

@media (max-width: 767px) {
    .partners-div {
        padding-right: 2px;
    }

    .partners-div a img {
        margin-right: 10px;
        margin-bottom: 10px;
        width: 90px;
        height: 64px;
    }
}
.media-slider-div {
 position: relative;
}

.media-slider {
 max-width: 65.5vw;
 margin: 2vw auto 0 auto;
}

.media-slider .splide__slide {
 width: 14.833vw !important;
 height: -webkit-min-content;
 height: min-content;
 border: none !important;
}

.media-slider .splide__slide .media-img {
 transition: 0.3s;
 width: 100% !important;
 border: 0.677vw solid #3f3f3f !important;
 cursor: pointer;
}

.media-slider .splide__slide:hover .media-img {
 border-color: var(--color-red) !important;
}

.media-slider .splide__slide.is-active {
 border: none !important;
}

.media-slider .splide__slide.is-active .media-img {
 border-color: var(--color-red) !important;
}

.media-slider .splide__slide img {
 width: 100%;
}

.media-slider .splide__slide h6 {
 font-family: var(--font-helvetica);
 font-weight: 500;
 font-size: 1.042vw;
 color: #707070;
 margin-top: 1.25vw;
}

.splide__arrows button {
 width: 2.083vw;
 height: 2.083vw;
 border-radius: 0;
 opacity: 1 !important;
 background: #393939;
 transition: 0.3s;
}

.splide__arrows button:hover {
 background: var(--color-red);
}

.splide__arrows button svg {
 width: 1.025vw;
 height: 0.817vw;
 fill: #bebebe;
}

.splide__arrow--next {
 top: 11vw !important;
 right: 0 !important;
}

.splide__arrow--prev {
 top: 11vw !important;

 left: unset !important;
 right: 2.3vw !important;
}

.media-slider-div .splide__track ul {
 grid-column-gap: 2.05vw;
 -webkit-column-gap: 2.05vw;
         column-gap: 2.05vw;
}

.media-slider-div .splide__arrow--next {
 top: 26vw !important;
}

.media-slider-div .splide__arrow--prev {
 top: 26vw !important;
}

.onlytest {
 position: absolute;
 bottom: 0vw;
 right: 2vw;
 width: 68.073vw;
 z-index: -1;
}

.media-modal-body {
 max-height: 700px;
 border: 0.677vw solid var(--color-red);
 overflow: hidden;
}

.media-modal-body img {
 width: 100%;
 height: 100%;
 max-height: 700px;
 object-fit: cover;
}

@media (max-width: 1199px) {
 .splide__arrows button {
  width: 40px;
  height: 40px;
  top: 140px !important;
 }

 .splide__arrows button svg {
  width: 12px;
  height: auto;
 }

 .splide__arrow--prev {
  right: 44px !important;
 }

 .media-slider {
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
 }

 .media-slider-div .splide__track ul {
  -webkit-column-count: 4;
          column-count: 4;
  grid-column-gap: 15px;
  -webkit-column-gap: 15px;
          column-gap: 15px;
  display: block !important;
  -webkit-transform: translateX(0) !important;
          transform: translateX(0) !important;
 }

 .media-slider-div .splide__arrows {
  display: none;
 }
 .media-slider-div .splide__track ul li {
  margin-bottom: 30px;
  width: 220px !important;
  word-wrap: break-word;
  margin-bottom: 20px;
  -webkit-column-break-inside: avoid;
  -moz-column-break-inside: avoid;
 }

 .media-slider .splide__slide .media-img {
  border: 6px solid #3f3f3f !important;
 }

 .media-slider .splide__slide h6 {
  margin-top: 20px;
  font-size: 16px;
 }

 .media-modal-body {
  width: auto;
  max-height: 70vh;
  border: 6px solid var(--color-red);
  overflow: hidden;
 }

 .media-modal-body img {
  width: 100%;
  height: 100%;
  max-height: 70vh;
  object-fit: cover;
 }
}

@media (max-width: 991px) {
 .media-slider-div .splide__track ul {
  -webkit-column-count: 3;
          column-count: 3;
 }
}

@media (max-width: 760px) {
 .media-slider-div .splide__track ul {
  -webkit-column-count: 2;
          column-count: 2;
 }
}

@media (max-width: 620px) {
 .splide__arrows button {
  width: 40px;
  height: 40px;
  top: 24vw !important;
 }
}

@media (max-width: 500px) {
 .media-slider-div .splide__track ul li {
  width: 40vw !important;
  margin-bottom: 12px;
 }

 .media-slider .splide__slide h6 {
  margin-top: 12px;
 }
}
/* 
s
s
s
s
s
s
s
 */
.hidden_btn {
 display: none;
 position: absolute;
 width: 50px;
 height: 50px;
 left: 7px;
 background-color: lightpink;
 opacity: 0.4;
 pointer-events: all;
}

.nulled {
 pointer-events: none;
}

@media (min-width: 1200px) and (max-height: 52vw) {
 .sidebar-bottom-div {
  position: relative;
  margin-top: 4vw;
 }

 .sidebar-bottom-section {
  background-color: var(--color-black);
 }

 .sidebar-navlinks-div {
  background-color: var(--color-black);
 }
}

@media (min-width: 1200px) and (max-height: 55vw) {
 .footer-creator {
  display: none;
 }

 .footer-links-div {
  bottom: 2.5vw;
 }

 .footer-img {
  height: 11vw;
 }

 .footer-paata-beridze {
  left: 27vw;
  font-size: 10vw;
 }
}

@media (min-width: 1200px) and (max-height: 51vw) {
 .footer-links-div {
  bottom: 0.5vw;
 }

 .footer-img {
  display: none;
 }

 .lonely-line {
  display: none;
 }

 .footer-paata-beridze {
  bottom: -4px;
 }
}

@media (min-width: 1200px) and (max-height: 49vw) {
 footer {
  display: none;
 }
}

.video-slider .splide__slide {
  width: 20.677vw !important;
  height: 25.677vw;
  border: none !important;
  background: #202020;
}

.video-slider-div .splide__track ul {
  grid-column-gap: 1.8vw;
  -webkit-column-gap: 1.8vw;
          column-gap: 1.8vw;
}

.video-slider-div .splide__arrow--next {
  top: inherit !important;
  bottom: -5vw;
}

.video-slider-div .splide__arrow--prev {
  top: inherit !important;
  bottom: -5vw;
}

.video-slider .splide__slide div {
  width: 100%;
  height: 12.031vw;
  overflow: hidden;
}

.video-slider .splide__slide div:hover img {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

.video-slider .splide__slide img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: 0.5s;
}

.slide__h3 {
  font-weight: 500;
  font-size: 1.25vw;
  line-height: 1.563vw;
  text-align: right;
  color: #992500;
  margin: 1.275vw 1.667vw;
}

.slide__p {
  height: 3.958vw;
  overflow-y: auto;
  font-weight: 300;
  font-size: 0.938vw;
  line-height: 1.354vw;
  text-align: right;
  color: #7d7d7d;
  margin: 0 0.833vw;
  padding: 0 0.521vw;
}

.v_slide__button {
  border: none;
  background: transparent;
  width: 4.323vw;
  margin-left: 75%;
  margin-top: 1.25vw;
}

.v_slide__button img {
  width: 100%;
}

@media (max-width: 1199px) {
  .video-slider-div ul {
    -webkit-transform: translateX(0px) !important;
            transform: translateX(0px) !important;
    display: grid !important;
    grid-template-columns: 1fr 1fr 1fr;
    grid-row-gap: 28px;
    row-gap: 28px;
    grid-column-gap: 20px;
    -webkit-column-gap: 20px;
            column-gap: 20px;
  }

  .video-slider .splide__slide {
    width: 295px !important;
    height: 424px !important;
  }

  .video-slider .splide__slide div {
    height: 192px;
  }

  .slide__h3 {
    font-size: 20px;
    margin: 30px 32px 20px 32px;
    line-height: 30px;
  }

  .slide__p {
    height: 64px;
    font-weight: 300;
    font-size: 14px;
    line-height: 22px;
    margin: 0 14px;
    padding: 0 12px;
  }

  .v_slide__button {
    margin-left: 63.05%;
    margin-top: 28px;
    width: 83px;
  }

  .video-slider-div .splide__arrows {
    display: none;
  }
}

@media (max-width: 991px) {
  .video-slider-div ul {
    grid-template-columns: 1fr 1fr;
    grid-row-gap: 28px;
    row-gap: 28px;
    grid-column-gap: 20px;
    -webkit-column-gap: 20px;
            column-gap: 20px;
  }
}

@media (max-width: 640px) {
  .video-slider-div ul {
    grid-template-columns: 1fr;
    grid-row-gap: 28px;
    row-gap: 28px;
    grid-column-gap: 20px;
    -webkit-column-gap: 20px;
            column-gap: 20px;
  }
}

.b_slide__button {
  padding: 0 1.302vw;
  height: 1.979vw;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 300;
  font-size: 0.729vw;
  line-height: 1.563vw;
  color: white;
  font-family: var(--font-helvetica);
  background: var(--color-red);
  border: none;
  float: right;
  margin-right: 1.667vw;
  margin-top: 1.25vw;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  text-decoration: none;
}

.b_slide__button:hover {
  color: white;
}
/* 
.testtest {
  width: 1.823vw;
  margin-right: 0.677vw;
} */

.go-back {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 0 1.146vw;
  height: 1.979vw;
  background-color: var(--color-red);
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 300;
  font-size: 0.729vw;
  color: #fff;
  text-transform: capitalize;
  text-decoration: none;
  margin-top: 10.003px;
  margin-bottom: 0.833vw;
}

.go-back:hover {
  color: #fff;
}

.go-back p {
  margin-left: 0.625vw;
}

.go-back svg {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

.book-content-div {
  margin: 0 3.75vw;
}

.book-content {
  display: flex;

  position: relative;
}

.book-content-img {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  max-width: 20vw;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  max-height: 25.521vw;
  overflow: hidden;
  position: sticky;
}

.book-content-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: 0.5s;
}

.book-d-flex {
  width: 100%;
  max-height: 25.521vw;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  margin-left: 3.021vw;
  grid-row-gap: 1.354vw;
  row-gap: 1.354vw;
}

.book-d-flex.discography {
  flex-direction: row-reverse;
  justify-content: flex-end;
  grid-column-gap: 50px;
  -webkit-column-gap: 50px;
          column-gap: 50px;
}

.book-d-flex.discography .book-inner-content h6:nth-child(3) {
  margin-top: 16px;
}

.book-content-img:hover img {
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
}

.book-inner-content {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.book-inner-content h6 {
  text-transform: capitalize;
  display: flex;
  font-weight: 500;
  font-size: 1.458vw;
  line-height: 1.563vw;
  color: var(--color-red);
  margin-bottom: 0.625vw;
}

.book-inner-content h6 svg {
  margin-right: 0.625vw;
}

.book-inner-content p {
  width: 15.104vw;
  font-weight: 500;
  font-size: 0.833vw;
  line-height: 1.354vw;
  color: #707070;
}

.book-inner-content ul {
  margin: 0;
  padding: 0;
  list-style: none;
  font-weight: 500;
  font-size: 0.833vw;
  line-height: 1.354vw;
  color: #707070;
}

@media (max-width: 1199px) {
  .b_slide__button {
    padding: 0 22px;
    height: 32px;
    font-size: 12px;
    line-height: 30px;
    margin-top: 26px;
  }

  .book-content-div {
    margin: 0 32px;
  }

  .go-back {
    display: none;
  }

  .book-content-div {
    margin: 18px 32px 0 32px;
  }

  .book-content {
    display: flex;
    max-height: -webkit-max-content;
    max-height: max-content;
    overflow-y: auto;
  }

  .book-content-img {
    overflow: hidden;
    position: relative;
    max-height: -webkit-fit-content;
    max-height: -moz-fit-content;
    max-height: fit-content;
    max-width: 300px;
  }

  .book-content-img img {
    width: 100%;
    height: auto;
    object-fit: cover;
    transition: 0.5s;
  }

  .book-d-flex {
    padding-left: 0;
    display: flex;
    flex-direction: column;
    margin-left: 58.003px;
    grid-row-gap: 25.997px;
    row-gap: 25.997px;
  }

  .book-content-img:hover img {
    -webkit-transform: scale(1.05);
            transform: scale(1.05);
  }

  .book-inner-content {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
  }

  .book-inner-content h6 {
    text-transform: capitalize;
    display: flex;
    font-weight: 500;
    font-size: 27.994px;
    line-height: 30.01px;
    color: var(--color-red);
    margin-bottom: 12px;
  }

  .book-inner-content h6 svg {
    margin-right: 12px;
  }

  .book-inner-content p {
    width: 289.997px;
    font-weight: 500;
    font-size: 15.994px;
    line-height: 25.997px;
    color: #707070;
  }

  .book-inner-content ul {
    margin: 0;
    padding: 0;
    list-style: none;
    font-weight: 500;
    font-size: 15.994px;
    line-height: 25.997px;
    color: #707070;
  }

  .book-d-flex {
    max-height: 800px;
  }

  .book-d-flex.discography {
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: flex-start;
    grid-column-gap: 20px;
    -webkit-column-gap: 20px;
            column-gap: 20px;
  }
}

@media (max-width: 810px) {
  .book-d-flex {
    margin-left: 32px;
  }

  .book-content-img {
    width: 100%;
    height: auto;
  }

  .book-content-img img {
    width: 100%;
    height: auto;
  }
}

@media (max-width: 640px) {
  .book-d-flex {
    max-height: -webkit-max-content;
    max-height: max-content;
  }

  .book-content {
    flex-direction: column;
    grid-row-gap: 38px;
    row-gap: 38px;
  }

  .book-d-flex {
    margin-left: 0;
  }

  .book-content-img {
    max-width: 100%;
  }
}

.photo-album-section .react-photo-gallery--gallery {
  margin: 0 3.75vw;
  max-height: 34vw;
  overflow-y: auto;
}

@media (max-width: 1199px) {
  .photo-album-section .react-photo-gallery--gallery {
    margin: 0 32px;
    max-height: none;
    max-height: initial;
    overflow-x: hidden;
  }
}

