.photo-album-section .react-photo-gallery--gallery {
  margin: 0 3.75vw;
  max-height: 34vw;
  overflow-y: auto;
}

@media (max-width: 1199px) {
  .photo-album-section .react-photo-gallery--gallery {
    margin: 0 32px;
    max-height: initial;
    overflow-x: hidden;
  }
}
