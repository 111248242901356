body.offcanvas-active {
  overflow: hidden;
}

.offcanvas-header {
  display: none;
}

.screen-darken {
  height: 100%;
  width: 0%;
  z-index: 30;
  position: fixed;
  top: 0;
  right: 0;
  opacity: 0;
  visibility: hidden;
  background-color: rgba(13, 13, 13, 0.94);
  transition: opacity 0.2s;
}

.screen-darken.active {
  z-index: 10;
  transition: opacity 0.3s, width 0s;
  opacity: 1;
  width: 100%;
  visibility: visible;
}

/* ============ mobile view ============ */
@media all and (max-width: 1199px) {
  .offcanvas-header {
    display: block;
  }

  .mobile-offcanvas {
    visibility: hidden;
    transform: translatey(-100%);
    border-radius: 0;
    display: block;
    position: absolute;
    margin: 0 auto;
    top: 0;
    left: 0;
    right: 0;
    height: auto;
    z-index: 10;
    width: 300px;
    overflow-y: scroll;
    overflow-x: hidden;
    transition: visibility 0.3s;
  }

  .mobile-offcanvas.show {
    visibility: visible;
    transform: translatey(0);
  }
  .mobile-offcanvas .container,
  .mobile-offcanvas .container-fluid {
    display: block;
  }
}
/* ============ mobile view .end// ============ */

.menu-btn {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  cursor: pointer;
  transition: all 0.5s ease-in-out;
  /* border: 3px solid #fff; */
  background-color: black;
}
.menu-btn__burger {
  width: 27px;
  height: 3px;
  background: #c8c8c8;
  transition: all 0.5s ease-in-out;
}
.menu-btn__burger::before,
.menu-btn__burger::after {
  content: "";
  position: absolute;
  width: 27px;
  height: 3px;
  background: #c8c8c8;
  transition: all 0.5s ease-in-out;
}
.menu-btn__burger::before {
  transform: translateY(-10px);
}
.menu-btn__burger::after {
  transform: translateY(10px);
}
/* ANIMATION */
.menu-btn.open .menu-btn__burger {
  background: transparent;
  box-shadow: none;
}
.menu-btn.open .menu-btn__burger::before {
  transform: rotate(45deg) translate(-1px, 1px);
}
.menu-btn.open .menu-btn__burger::after {
  transform: rotate(-45deg) translate(-1px, -1px);
}

@media (max-width: 767px) {
  .menu-btn__burger {
    width: 27px;
    height: 3px;
    background: #606060;
    transition: all 0.5s ease-in-out;
  }
  .menu-btn__burger::before,
  .menu-btn__burger::after {
    content: "";
    position: absolute;
    width: 27px;
    height: 3px;
    background: #606060;
    transition: all 0.5s ease-in-out;
  }

  .menu-btn {
    background-color: white;
    width: 50px;
    height: 50px;
  }

  .menu-btn__burger::before {
    transform: translateY(-8px);
  }

  .menu-btn__burger::after {
    transform: translateY(8px);
  }

  .sidebar-navlinks-div ul li a {
    font-size: 24px;
    line-height: 38px;
  }

  .nav-beflex {
    display: none;
  }
}

@media (max-width: 767px) {
  .mobile-offcanvas {
    transform: translatex(-100%);
    width: auto;
    padding: 0px 22px;
  }

  .mobile-offcanvas.show {
    transform: translateX(0);
  }
}
