.main-lang-div {
  position: absolute;
  width: 9.736vw;
  height: 3.807vw;
  overflow: hidden;
  top: 0;
  right: 0;
  transition: height 0.5s ease-in-out;
  z-index: 110000000;
}

.main-lang-div:hover {
  height: 11.42vw;
  transition: all 0.3s ease-in-out;
}

.active-lang {
  margin-left: 1.245vw;
  margin-right: 1.391vw;
}

.lang-div {
  width: 9.736vw;
  height: 3.807vw;
  background-color: #202020;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  cursor: pointer;
  text-transform: capitalize;
  color: #bebebe;
  font-family: var(--font-helvetica);
}

.lang-icon-div {
  width: 1.318vw;
}

.lang-icon {
  width: 100%;
  height: 100%;
}

.main-lang-div:hover .active-lang-div .lang-arrow-div {
  transform: rotate(0deg);
}

.lang-arrow-div {
  width: 1.318vw;
  transition: all 0.3s ease-in-out;
  transform: rotate(180deg);
}

.lang-arrow {
  width: 100%;
  height: 100%;
}

.lang-div:hover {
  background-color: #992500;
}

.lang-div p {
  font-size: 1vw;
}

@media (max-width: 1199px) {
  .main-lang-div {
    z-index: 11;
    position: absolute;
    width: 133px;
    height: 85px;
    overflow: hidden;
    top: 0;
    right: 85px;
    transition: height 0.5s ease-in-out;
  }

  .main-lang-div:hover {
    height: 189px;
    transition: all 0.3s ease-in-out;
  }

  .active-lang {
    margin-left: 18px;
    margin-right: 18px;
  }

  .lang-div {
    width: 133px;
    height: 52px;
    background-color: #202020;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    cursor: pointer;
    text-transform: capitalize;
    color: #bebebe;
    font-family: var(--font-helvetica);
  }

  .active-lang-div {
    width: 133px;
    height: 85px;
  }

  .lang-icon-div {
    width: 15px;
  }

  .lang-icon {
    width: 100%;
    height: 100%;
  }

  .main-lang-div:hover .active-lang-div .lang-arrow-div {
    transform: rotate(0deg);
  }

  .lang-arrow-div {
    width: 15px;
    transition: all 0.3s ease-in-out;
    transform: rotate(180deg);
  }

  .lang-arrow {
    width: 100%;
    height: 100%;
  }

  .lang-div:hover {
    background-color: #992500;
  }

  .lang-div p {
    font-size: 16px;
  }
}

@media (max-width: 767px) {
  .main-lang-div {
    top: 0;
    right: 23px;
    position: absolute;
    display: flex;
    width: auto;
    height: auto;
    margin-top: 10px;
  }

  .active-lang-div {
    height: min-content;
    width: 34px;
  }

  .lang-div {
    background-color: transparent;
    height: min-content;
    width: 40px;
  }

  .lang-arrow-div {
    display: none;
  }

  .lang-icon-div {
    display: none;
  }

  .lang-burger {
    z-index: 11;
    background-color: white;
    height: 54px;
    position: relative;
  }

  .lang-div:hover {
    background-color: transparent;
    color: var(--color-red);
  }

  .lang-div p {
    color: black;
  }

  .active-lang-div p {
    color: var(--color-red);
  }

  @supports (position: sticky) {
    .lang-burger {
      position: sticky;
    }
  }
}

@media (min-width: 1200px) {
  .lang-burger {
    width: 100vw;
    position: fixed;
    top: 0;
  }
}

.blaaaa {
  width: 40px;
  height: 40px;
  border: 1px solid red;
  position: absolute;
  top: 4px;
  left: 200px;
  background-color: transparent;
}
