.media-slider-div {
 position: relative;
}

.media-slider {
 max-width: 65.5vw;
 margin: 2vw auto 0 auto;
}

.media-slider .splide__slide {
 width: 14.833vw !important;
 height: min-content;
 border: none !important;
}

.media-slider .splide__slide .media-img {
 transition: 0.3s;
 width: 100% !important;
 border: 0.677vw solid #3f3f3f !important;
 cursor: pointer;
}

.media-slider .splide__slide:hover .media-img {
 border-color: var(--color-red) !important;
}

.media-slider .splide__slide.is-active {
 border: none !important;
}

.media-slider .splide__slide.is-active .media-img {
 border-color: var(--color-red) !important;
}

.media-slider .splide__slide img {
 width: 100%;
}

.media-slider .splide__slide h6 {
 font-family: var(--font-helvetica);
 font-weight: 500;
 font-size: 1.042vw;
 color: #707070;
 margin-top: 1.25vw;
}

.splide__arrows button {
 width: 2.083vw;
 height: 2.083vw;
 border-radius: 0;
 opacity: 1 !important;
 background: #393939;
 transition: 0.3s;
}

.splide__arrows button:hover {
 background: var(--color-red);
}

.splide__arrows button svg {
 width: 1.025vw;
 height: 0.817vw;
 fill: #bebebe;
}

.splide__arrow--next {
 top: 11vw !important;
 right: 0 !important;
}

.splide__arrow--prev {
 top: 11vw !important;

 left: unset !important;
 right: 2.3vw !important;
}

.media-slider-div .splide__track ul {
 column-gap: 2.05vw;
}

.media-slider-div .splide__arrow--next {
 top: 26vw !important;
}

.media-slider-div .splide__arrow--prev {
 top: 26vw !important;
}

.onlytest {
 position: absolute;
 bottom: 0vw;
 right: 2vw;
 width: 68.073vw;
 z-index: -1;
}

.media-modal-body {
 max-height: 700px;
 border: 0.677vw solid var(--color-red);
 overflow: hidden;
}

.media-modal-body img {
 width: 100%;
 height: 100%;
 max-height: 700px;
 object-fit: cover;
}

@media (max-width: 1199px) {
 .splide__arrows button {
  width: 40px;
  height: 40px;
  top: 140px !important;
 }

 .splide__arrows button svg {
  width: 12px;
  height: auto;
 }

 .splide__arrow--prev {
  right: 44px !important;
 }

 .media-slider {
  max-width: fit-content;
 }

 .media-slider-div .splide__track ul {
  column-count: 4;
  column-gap: 15px;
  display: block !important;
  transform: translateX(0) !important;
 }

 .media-slider-div .splide__arrows {
  display: none;
 }
 .media-slider-div .splide__track ul li {
  margin-bottom: 30px;
  width: 220px !important;
  word-wrap: break-word;
  margin-bottom: 20px;
  -webkit-column-break-inside: avoid;
  -moz-column-break-inside: avoid;
 }

 .media-slider .splide__slide .media-img {
  border: 6px solid #3f3f3f !important;
 }

 .media-slider .splide__slide h6 {
  margin-top: 20px;
  font-size: 16px;
 }

 .media-modal-body {
  width: auto;
  max-height: 70vh;
  border: 6px solid var(--color-red);
  overflow: hidden;
 }

 .media-modal-body img {
  width: 100%;
  height: 100%;
  max-height: 70vh;
  object-fit: cover;
 }
}

@media (max-width: 991px) {
 .media-slider-div .splide__track ul {
  column-count: 3;
 }
}

@media (max-width: 760px) {
 .media-slider-div .splide__track ul {
  column-count: 2;
 }
}

@media (max-width: 620px) {
 .splide__arrows button {
  width: 40px;
  height: 40px;
  top: 24vw !important;
 }
}

@media (max-width: 500px) {
 .media-slider-div .splide__track ul li {
  width: 40vw !important;
  margin-bottom: 12px;
 }

 .media-slider .splide__slide h6 {
  margin-top: 12px;
 }
}
/* 
s
s
s
s
s
s
s
 */
.hidden_btn {
 display: none;
 position: absolute;
 width: 50px;
 height: 50px;
 left: 7px;
 background-color: lightpink;
 opacity: 0.4;
 pointer-events: all;
}

.nulled {
 pointer-events: none;
}

@media (min-width: 1200px) and (max-height: 52vw) {
 .sidebar-bottom-div {
  position: relative;
  margin-top: 4vw;
 }

 .sidebar-bottom-section {
  background-color: var(--color-black);
 }

 .sidebar-navlinks-div {
  background-color: var(--color-black);
 }
}

@media (min-width: 1200px) and (max-height: 55vw) {
 .footer-creator {
  display: none;
 }

 .footer-links-div {
  bottom: 2.5vw;
 }

 .footer-img {
  height: 11vw;
 }

 .footer-paata-beridze {
  left: 27vw;
  font-size: 10vw;
 }
}

@media (min-width: 1200px) and (max-height: 51vw) {
 .footer-links-div {
  bottom: 0.5vw;
 }

 .footer-img {
  display: none;
 }

 .lonely-line {
  display: none;
 }

 .footer-paata-beridze {
  bottom: -4px;
 }
}

@media (min-width: 1200px) and (max-height: 49vw) {
 footer {
  display: none;
 }
}
