.bio-swap {
  --bs-gutter-x: 0;
  flex-direction: row-reverse;
}

.biography-photo-div {
  margin-left: auto;
  width: 24.271vw;
  height: 38.188vw;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.biography-photo-div img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.page-title {
  text-transform: capitalize;
  font-family: var(--font-helvetica);
  font-weight: 300;
  font-size: 3.125vw;
  color: var(--color-light-grey);
  margin-left: 3.75vw;
  margin-top: 3.333vw;
}

.biography-text-div {
  margin-top: 2.708vw;
  margin-left: 3.75vw;
  padding-right: 4vw;
  width: 40.292vw;
  height: 22.958vw;
  overflow-y: auto;
  font-family: var(--font-helvetica);
  font-weight: 300;
  font-size: 0.938vw;
  line-height: 1.354vw;
  color: var(--color-light-grey);
}

@media (max-width: 1200px) {
  .biography-photo-div {
    width: 100%;
    height: 400px;
  }

  .biography-photo-div img {
    object-fit: cover;
  }

  .page-title {
    width: auto;
    font-size: 40px;
    margin-left: 32px;
    margin-right: 32px;
    margin-top: 24px;
  }

  .biography-text-div {
    line-height: 32px;
    font-size: 20px;
    margin-top: 18px;
    width: auto;
    margin-left: 32px;
    margin-right: 32px;
    padding-right: 32px;
    height: 520px;
  }
}

@media (max-width: 767px) {
  .biography-photo-div {
    width: 100%;
    height: 200px;
  }

  .page-title {
    width: auto;
    font-size: 35px;
    margin-left: 32px;
    margin-right: 32px;
    margin-top: 24px;
  }

  .biography-text-div {
    line-height: 26px;
    font-size: 15px;
    margin-top: 18px;
    width: auto;
    margin-left: 32px;
    margin-right: 32px;
    padding-right: 32px;
    height: 320px;
  }
}
