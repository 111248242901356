.home-slider-div {
  position: relative;
}

.primary-slider {
  width: 100%;
  max-width: 100%;
}

.secondary-slider .splide__track ul {
  column-gap: 1.1vw;
}

.primary-slider img {
  max-width: 100%;
  object-fit: cover;
  width: 100%;
  height: 100%;
  max-height: 36.4vw;
}

.primary-slider button {
  display: none;
}

.secondary-slider {
  right: 2.76vw;
  bottom: -3.802vw;
  margin: 0 auto;
  position: absolute !important;
  max-width: 47vw !important;
}

.secondary-slider .splide__slide {
  transition: 0.3s;
  height: 8.802vw;
  border: 0.677vw solid #3f3f3f !important;
}

.secondary-slider .splide__slide:hover {
  border-color: var(--color-red) !important;
}

.secondary-slider .splide__slide.is-active {
  border: 0.677vw solid var(--color-red) !important;
}

.secondary-slider .splide__slide img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media (max-width: 1199px) {
  .secondary-slider .splide__slide:hover {
    border-color: #3f3f3f !important;
  }

  .home-slider-div {
    margin-bottom: 100px;
  }

  .primary-slider img {
    max-height: 400px;
  }

  .secondary-slider .splide__slide {
    height: 105px;
    border: 6px solid #3f3f3f !important;
  }

  .secondary-slider .splide__slide.is-active {
    border: 6px solid var(--color-red) !important;
  }

  .secondary-slider .splide__track ul {
    column-gap: 16px;
  }

  .secondary-slider {
    max-width: 572px !important;
  }
}

@media (max-width: 767px) {
  .primary-slider img {
    max-height: 300px;
  }
}

@media (max-width: 620px) {
  .secondary-slider .splide__slide {
    height: 16vw;
    border: 1.6vw solid #992500;
  }

  .secondary-slider .splide__track ul {
    max-width: 90.95vw;
    grid-gap: 4.267vw;
  }

  .secondary-slider {
    left: 50%; /* Position X halfway in */
    transform: translate(-50%, 0%);
    right: auto;
  }
}

@media (max-width: 575px) {
  .primary-slider img {
    max-height: 200px;
  }
}
