/* =\\=== R O O T ===//= */

:root {
 /* colors: */
 --color-red: #992500;
 --color-grey: #2e2e2e;
 --color-black: #0d0d0d;
 --color-light-grey: #707070;
 --color-light-grey2: #4c4c4c;
 --color-light-grey3: #c8c8c8;
 --color-light-grey4: #606060;
 --color-background: #191919;

 /* fonts: */
 --font-palace: "palace", sans-serif;
 --font-helvetica: "Helvetica Neue", sans-serif;
}

* {
 font-family: var(--font-helvetica) !important;
}

.footer-paata-beridze {
 font-family: var(--font-palace) !important;
}

*::selection {
 background-color: var(--color-red);
}

/* =\\=== S C R O L L ===//= */

::-webkit-scrollbar,
::-webkit-scrollbar-thumb,
::-webkit-scrollbar-track {
 width: 6px;
 border: none;
 background: transparent;
}

::-webkit-scrollbar-button,
::-webkit-scrollbar-track-piece,
::-webkit-scrollbar-corner,
::-webkit-resizer {
 display: none;
}

::-webkit-scrollbar-thumb {
 border-radius: 6px;
 background-color: #4a4a4a;
}

::-webkit-scrollbar-track {
 background-image: url(./img/vertical-scroll.svg);
 background-repeat: repeat-y;
 background-size: contain;
}
