.partners-div {
    width: auto;
    height: 32.292vw;
    margin: 0 3.75vw;
    overflow-y: auto;
    margin-top: 2.865vw;
}

.partners-div a img {
    width: 5.729vw;
    height: 4.063vw;
    object-fit: cover;
    margin-right: 0.781vw;
    margin-bottom: 1.146vw;
}

@media (max-width: 1199px) {
    .partners-div {
        padding-right: 20px;
        width: auto;
        height: 500px;
        margin: 0 32px;
        overflow-y: auto;
        margin-top: 2.865vw;
    }
    
    .partners-div a img {
        width: 110px;
        height: 80px;
        object-fit: cover;
        margin-right: 15px;
        margin-bottom: 22px;
    }
}

@media (max-width: 767px) {
    .partners-div {
        padding-right: 2px;
    }

    .partners-div a img {
        margin-right: 10px;
        margin-bottom: 10px;
        width: 90px;
        height: 64px;
    }
}