.b_slide__button {
  padding: 0 1.302vw;
  height: 1.979vw;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 300;
  font-size: 0.729vw;
  line-height: 1.563vw;
  color: white;
  font-family: var(--font-helvetica);
  background: var(--color-red);
  border: none;
  float: right;
  margin-right: 1.667vw;
  margin-top: 1.25vw;
  width: fit-content;
  text-decoration: none;
}

.b_slide__button:hover {
  color: white;
}
/* 
.testtest {
  width: 1.823vw;
  margin-right: 0.677vw;
} */

.go-back {
  width: fit-content;
  padding: 0 1.146vw;
  height: 1.979vw;
  background-color: var(--color-red);
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 300;
  font-size: 0.729vw;
  color: #fff;
  text-transform: capitalize;
  text-decoration: none;
  margin-top: 10.003px;
  margin-bottom: 0.833vw;
}

.go-back:hover {
  color: #fff;
}

.go-back p {
  margin-left: 0.625vw;
}

.go-back svg {
  transform: rotate(180deg);
}

.book-content-div {
  margin: 0 3.75vw;
}

.book-content {
  display: flex;

  position: relative;
}

.book-content-img {
  width: fit-content;
  max-width: 20vw;
  height: fit-content;
  max-height: 25.521vw;
  overflow: hidden;
  position: sticky;
}

.book-content-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: 0.5s;
}

.book-d-flex {
  width: 100%;
  max-height: 25.521vw;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  margin-left: 3.021vw;
  row-gap: 1.354vw;
}

.book-d-flex.discography {
  flex-direction: row-reverse;
  justify-content: flex-end;
  column-gap: 50px;
}

.book-d-flex.discography .book-inner-content h6:nth-child(3) {
  margin-top: 16px;
}

.book-content-img:hover img {
  transform: scale(1.05);
}

.book-inner-content {
  height: fit-content;
}

.book-inner-content h6 {
  text-transform: capitalize;
  display: flex;
  font-weight: 500;
  font-size: 1.458vw;
  line-height: 1.563vw;
  color: var(--color-red);
  margin-bottom: 0.625vw;
}

.book-inner-content h6 svg {
  margin-right: 0.625vw;
}

.book-inner-content p {
  width: 15.104vw;
  font-weight: 500;
  font-size: 0.833vw;
  line-height: 1.354vw;
  color: #707070;
}

.book-inner-content ul {
  margin: 0;
  padding: 0;
  list-style: none;
  font-weight: 500;
  font-size: 0.833vw;
  line-height: 1.354vw;
  color: #707070;
}

@media (max-width: 1199px) {
  .b_slide__button {
    padding: 0 22px;
    height: 32px;
    font-size: 12px;
    line-height: 30px;
    margin-top: 26px;
  }

  .book-content-div {
    margin: 0 32px;
  }

  .go-back {
    display: none;
  }

  .book-content-div {
    margin: 18px 32px 0 32px;
  }

  .book-content {
    display: flex;
    max-height: max-content;
    overflow-y: auto;
  }

  .book-content-img {
    overflow: hidden;
    position: relative;
    max-height: fit-content;
    max-width: 300px;
  }

  .book-content-img img {
    width: 100%;
    height: auto;
    object-fit: cover;
    transition: 0.5s;
  }

  .book-d-flex {
    padding-left: 0;
    display: flex;
    flex-direction: column;
    margin-left: 58.003px;
    row-gap: 25.997px;
  }

  .book-content-img:hover img {
    transform: scale(1.05);
  }

  .book-inner-content {
    height: fit-content;
  }

  .book-inner-content h6 {
    text-transform: capitalize;
    display: flex;
    font-weight: 500;
    font-size: 27.994px;
    line-height: 30.01px;
    color: var(--color-red);
    margin-bottom: 12px;
  }

  .book-inner-content h6 svg {
    margin-right: 12px;
  }

  .book-inner-content p {
    width: 289.997px;
    font-weight: 500;
    font-size: 15.994px;
    line-height: 25.997px;
    color: #707070;
  }

  .book-inner-content ul {
    margin: 0;
    padding: 0;
    list-style: none;
    font-weight: 500;
    font-size: 15.994px;
    line-height: 25.997px;
    color: #707070;
  }

  .book-d-flex {
    max-height: 800px;
  }

  .book-d-flex.discography {
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: flex-start;
    column-gap: 20px;
  }
}

@media (max-width: 810px) {
  .book-d-flex {
    margin-left: 32px;
  }

  .book-content-img {
    width: 100%;
    height: auto;
  }

  .book-content-img img {
    width: 100%;
    height: auto;
  }
}

@media (max-width: 640px) {
  .book-d-flex {
    max-height: max-content;
  }

  .book-content {
    flex-direction: column;
    row-gap: 38px;
  }

  .book-d-flex {
    margin-left: 0;
  }

  .book-content-img {
    max-width: 100%;
  }
}
